const generateId = (length = 16, noUniqueChars = false) => {
    var chars = noUniqueChars
        ? Date.now() + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        : Date.now() + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
    var password = '';
    for (var i = 0; i < length; i++) {
        var randomChar = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomChar, randomChar + 1);
    }
    return password;
};

export default generateId;
