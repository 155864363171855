const dev = {
    PAYSTACK_KEY: 'pk_test_d22948e9dfcc5ad40f54ed4b9b43ac176bdb908d',
    EXCHANGE_RATES_BASE_URL: 'https://openexchangerates.org/api/latest.json?app_id=f795db577bff4d708961fe7a10c0b0d1',
    FLUTTERWAVE_KEY: 'FLWPUBK_TEST-6bb95e133e1171b781625c129b3e1dc7-X',
    LAZERPAY_KEY: 'pk_test_37R37dn0xSoeTRBiqGdpMcReWQ7Oqs9X8etlzXUhCgZnKqr6wh',
    TLI_AFFILIATE_ID: '9rlU3MGnl7YJNAfJXXMj9WDVejp1',
    FB: {
        apiKey: 'AIzaSyAFzZDHJzQVZLGPU5jUtiEnNRE5e4oc9mM',
        authDomain: 'tli-skills-e854e.firebaseapp.com',
        projectId: 'tli-skills-e854e',
        storageBucket: 'tli-skills-e854e.appspot.com',
        messagingSenderId: '1039409679636',
        appId: '1:1039409679636:web:71a83fa6dd7417b043c601',
        measurementId: 'G-WH254BEWJX',
    },
    BASE_URL: 'https://tli-backend.onrender.com',
};

const prod = {
    PAYSTACK_KEY: 'pk_live_2218e8d287cc9649064db4a73f755421b308a111',
    EXCHANGE_RATES_BASE_URL: 'https://openexchangerates.org/api/latest.json?app_id=f795db577bff4d708961fe7a10c0b0d1',
    FLUTTERWAVE_KEY: 'FLWPUBK-ef99c5f9f329cf18436dbfbc9e2c508a-X',
    LAZERPAY_KEY: 'pk_live_b0G8aRzegksQTNziDEeCzkqQeXKXhXty6nUy5dDMhZJMAr6hM5',
    TLI_AFFILIATE_ID: 'a3qrx5IicmRpjCCHuMMD47YSjOP2',
    FB: {
        apiKey: 'AIzaSyApTmSE9oQjoFdeabVvwwJsp15_u17x_p4',
        authDomain: 'tli-skills-8e929.firebaseapp.com',
        projectId: 'tli-skills-8e929',
        storageBucket: 'tli-skills-8e929.appspot.com',
        messagingSenderId: '222149031972',
        appId: '1:222149031972:web:d0e123a5f48ad79e47c0b1',
        measurementId: 'G-MQSQ31WSEH',
    },
    BASE_URL: 'https://us-central1-tli-skills-8e929.cloudfunctions.net/api',
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default config;
