import React, { useEffect, useRef, useState } from 'react';

const Dropdown = ({
    label,
    handleChange,
    name,
    value,
    placeholder,
    dropdownOptions,
    dropdown,
    dropdownContainerClasses,
    dropdownItemsClasses,
    className,
    dropdownClassName,
}) => {
    const [open, setOpen] = useState(false);
    const selectedOption = dropdownOptions?.find((item) => item.value === value);
    const ref = useRef(null);

    const handleSelect = (value) => {
        handleChange?.(name, value);
        setOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target) && open) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, open]);

    return (
        <div className={`relative ${className}`}>
            {dropdown ? (
                <div onClick={() => setOpen(!open)} className="w-fit cursor-pointer">
                    {dropdown}
                </div>
            ) : (
                <div
                    onClick={() => setOpen(!open)}
                    className="flex gap-2.5 justify-between cursor-pointer items-center border border-gray-200 py-3 px-4 outline-0 w-full h-[50px] text-sm bg-gray-50"
                >
                    <p>
                        {Boolean(value) ? (
                            <span className="flex items-center">
                                {selectedOption?.icon && <> {selectedOption?.icon}&nbsp;&nbsp;&nbsp; </>}
                                {selectedOption?.label}
                            </span>
                        ) : (
                            placeholder
                        )}
                    </p>
                    <span className="material-icons">arrow_drop_down</span>
                </div>
            )}

            {open &&
                (dropdownOptions && dropdownOptions?.length > 0 ? (
                    <div
                        ref={ref}
                        className={`${
                            dropdown ? 'min-w-max' : ''
                        } py-2 bg-white absolute top-[101%] w-full h-auto max-h-64 z-20 overflow-auto border ${dropdownContainerClasses} ${dropdownClassName}`}
                    >
                        {dropdownOptions.map((option) => {
                            return option.customChild ? (
                                <div>{option.customChild}</div>
                            ) : (
                                <div
                                    key={option.value}
                                    onClick={() => {
                                        if (option?.action && !option?.disabled) {
                                            option.action?.();
                                        }
                                        if (!option?.disabled) {
                                            handleSelect(option?.value);
                                        }
                                    }}
                                    className={`flex items-center px-4 justify-between hover:bg-gray-50 ${
                                        selectedOption?.value === option?.value && 'bg-gray-50'
                                    } ${option?.disabled && 'opacity-40'} cursor-pointer ${dropdownItemsClasses}`}
                                >
                                    <p className={`text-sm flex gap-3 py-3 items-center text-inherit`}>
                                        {option?.icon && <div>{option?.icon}</div>}{' '}
                                        <span className="text-inherit">{option?.label}</span>
                                    </p>
                                    {selectedOption?.value === option?.value && (
                                        <span className="material-icons text-base">done</span>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div
                        className={`py-2 bg-white absolute top-[101%] w-full h-auto max-h-64 z-20 overflow-auto border ${dropdownClassName}`}
                        id="custom-dropdown"
                    >
                        <div className="flex px-4 justify-between hover:bg-mvx-light-blue cursor-pointer">
                            <p className="text-sm flex gap-3 py-3 items-center">
                                <span>No Data</span>
                            </p>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default Dropdown;
