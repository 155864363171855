import Nav from 'layouts/components/Nav';
import SideBar from 'layouts/components/SideBar';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const PagesLayout = () => {
    const { pathname } = useLocation();
    const scrollElmRef = useRef();
    useEffect(() => {
        // scrollElmRef?.current && scrollVertical(document.getElementById(scrollElmRef.current));
    }, [pathname]);

    return (
        <>
            <Nav />
            <SideBar />

            <main
                className="h-[calc(100vh-60.0938px)] md:h-[calc(100vh-61px)] overflow-y-auto"
                id="the-scroll"
                ref={scrollElmRef}
            >
                <Outlet />
            </main>
        </>
    );
};

export default PagesLayout;
