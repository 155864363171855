import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const Notification = ({ title, content, time, className, link }) => {
    return (
        <Link
            to={link ?? window.location.pathname}
            className={`block bg-white rounded w-full space-x-[10px] hover:bg-gray-100 py-[20px] px-[20px] ${className}`}
        >
            <p className="cursor-pointer font-semibold">{title}</p>
            <p className="text-gray-500 !ml-0 mt-[3px]">{`${content}`}</p>

            <p className="text-gray-400 mt-2 text-[12px] !ml-0">{moment(time).fromNow()}</p>
        </Link>
    );
};

export default Notification;
