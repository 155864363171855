import { createSlice } from '@reduxjs/toolkit';
import { getFromStorage, saveToStorage } from 'utils';

const initialState = {
    user: getFromStorage('user'),
    experts: null,
    affiliateAnalytics: null,
    notifications: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            saveToStorage('user', payload);
            state.user = payload;
        },
        setExperts: (state, { payload }) => {
            state.experts = payload;
        },
        setAffiliateAnalytics: (state, { payload }) => {
            state.affiliateAnalytics = payload;
        },
        setNotifications: (state, { payload }) => {
            state.notifications = payload;
        },
    },
});

export const { setUser, setExperts, setAffiliateAnalytics, setNotifications } = authSlice.actions;
export default authSlice.reducer;
