import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
    Timestamp,
    arrayUnion,
    collection,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    getFirestore,
    increment,
    limit,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
    writeBatch,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { getAuth, signInAnonymously, signOut } from 'firebase/auth';
import config from 'constants/config';
import { store } from 'redux/store';
import { setCourseCategories } from 'redux/slices/appSlice';
import { removeFromStorage } from './localStorageUtils';
import { setUser } from 'redux/slices/authSlice';
import { toast } from 'react-toastify';
import generateId from './generateId';
import capitalizeWords from './capitalizeWords';
import { PRICING } from 'constants';
import moment from 'moment';
import { formatDateValues } from './formatDateValues';

// TODO: Add SDKs for Firebase products that you want to use production and staging

// Initialize Firebase
const app = initializeApp(config.FB);
getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export const modifyFirebaseError = (firebaseString) => {
    const removedChars = firebaseString.replace(/[/-]/g, ' ');
    const updatedString = removedChars
        .split(' ')
        .filter((_, idx) => idx)
        .join(' ');
    return 'Request failed. ' + updatedString.charAt(0).toUpperCase() + updatedString.slice(1) + '!';
};

export const fetchCategories = async (setLoading) => {
    setLoading && setLoading(true);
    const user = store.getState().auth.user;
    try {
        if (!user) {
            await signInAnonymously(auth);
        }
        const courseCategoriesRef = await getDoc(doc(db, 'admin', 'courseCategories'));
        store.dispatch(setCourseCategories(courseCategoriesRef.data().allCategories.sort()));
    } catch (error) {
        console.log(error?.message);
        // toast.error('Fetching categories failed, please reload the page.');
    } finally {
        setLoading && setLoading(false);
    }
};

export const uploadFile = async (filePathAndName, file, setReturnType, setProgressFunc, setUploadTaskInstance) => {
    const storageRef = ref(storage, filePathAndName);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
        'state_changed',
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgressFunc && setProgressFunc(Math.floor(progress));
        },
        (error) => {
            console.log(error.code, error.message);
            error.code !== 'storage/canceled' && toast.error(error.message + '. Please reupload ' + file.name);
            setReturnType('error');
        },
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => setReturnType(downloadURL));
        }
    );

    setUploadTaskInstance && setUploadTaskInstance(uploadTask);
};

const addNewCourses = async (newCourses, paymentSuccessData, userId) => {
    const batch = writeBatch(db);

    newCourses.forEach((course) => {
        const expertId = course.expert.id;
        const courseRef = doc(collection(db, `purchasedCourses/${userId}/${userId}`));
        const expertCourseRef = doc(db, `expertCourses/${expertId}/${expertId}`, course.id);
        batch.set(courseRef, { ...course, paymentRef: paymentSuccessData, createdAt: serverTimestamp() });
        batch.update(expertCourseRef, { salesCount: increment(1), updatedAt: serverTimestamp() });
    });

    await batch.commit();
};

export const addCoursesToUserAndUpdateRole = async (paymentSuccessData, newCourses, setLoading, successFunc) => {
    const resolvedUser = store.getState().auth.user;
    setLoading && setLoading(true);
    try {
        const userRef = doc(db, 'users', resolvedUser.id);

        await addNewCourses(newCourses, paymentSuccessData, resolvedUser.id);

        await updateDoc(userRef, {
            roles: arrayUnion('achiever'),
            'activeStatuses.achiever': true,
            purchasedCoursesId: arrayUnion(...newCourses.map((crs) => crs.id)),
            updatedAt: serverTimestamp(),
        });

        const userSnapShot = await getDoc(userRef);
        const newUser = {
            ...userSnapShot.data(),
            ...formatDateValues(userSnapShot.data()),
        };

        store.dispatch(setUser(newUser));
        setLoading && setLoading('success');
        toast.success('Enrollment successful!');
        successFunc && successFunc();
    } catch (error) {
        setLoading && setLoading(false);
        toast.error(error.message);
    }
};

const fetchAffiliateId = async (referralCode) => {
    if (referralCode) {
        const q = query(collection(db, 'users'), where('referralCode', '==', referralCode), limit(1));

        const affiliateIdArr = [];
        const arrRef = await getDocs(q);
        arrRef.forEach((doc) => {
            doc.data().activeStatuses.member && affiliateIdArr.push(doc.id);
        });

        if (affiliateIdArr.length) {
            return affiliateIdArr[0];
        }
        return null;
    }
    return null;
};

/**
 * @param {string|Date} selectedDate - A date string or object
 */
export const getTimestamp = (selectedDate) => {
    const TimestampDate = new Date(selectedDate);
    TimestampDate.setHours(0, 0, 0, 0);
    return Timestamp.fromDate(TimestampDate);
};

export const updateNewMemberAfterPayment = async (
    userId,
    paymentSuccessData,
    successMessage,
    referralCode,
    isRenewal
) => {
    try {
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
            memberRegReference: paymentSuccessData,
            'activeStatuses.member': true,
            roles: arrayUnion('member'),
            referralCode: userId.split('').slice(0, 8).join(''),
            nextDuePayment: getTimestamp(moment().add(1, 'years')),
            updatedAt: serverTimestamp(),
        });
        const userSnapShot = await getDoc(userRef);
        const newUser = {
            ...userSnapShot.data(),
            ...formatDateValues(userSnapShot.data()),
        };

        const affiliateId = await fetchAffiliateId(referralCode);

        const affiliateAnalyticsRef = doc(
            db,
            'analytics/affiliates/affiliates',
            affiliateId || config.TLI_AFFILIATE_ID
        );
        const adminAnalyticsRef = doc(db, 'analytics', 'admin');

        const newReferral = {
            id: generateId(),
            userId,
            name: capitalizeWords(newUser.fullName),
            createdAt: new Date(),
        };

        const affiliateUpdateData = { ...newReferral, amount: Number(PRICING.MEMBER.PRICE) * 0.1 };
        const affiliateKeyToAdd = isRenewal ? 'renewalReferrals' : 'referrals';

        await setDoc(affiliateAnalyticsRef, { [affiliateKeyToAdd]: arrayUnion(affiliateUpdateData) }, { merge: true });

        const adminUpdateData = { ...newReferral, amount: Number(PRICING.MEMBER.PRICE) * 0.9 };
        const adminKeyToAdd = isRenewal ? 'renewalAffiliates' : 'affiliates';

        await setDoc(adminAnalyticsRef, { [adminKeyToAdd]: arrayUnion(adminUpdateData) }, { merge: true });

        toast.success(successMessage);
        removeFromStorage('referralCode');
        store.dispatch(setUser(newUser));
    } catch (error) {
        toast.error('Something went wrong. Please try again.');
        console.log(error.message);
    }
};

export const logout = async () => {
    try {
        await signOut(auth);
        store.dispatch(setUser(null));
    } catch (error) {
        toast.error(modifyFirebaseError(error.code) + ' Please try again.');
    }
};

export const fetchCollectionByQuery = async (collectionQuery, setLoading, setData, showError = false) => {
    setLoading(true);
    const dataArr = [];

    const user = store.getState().auth.user;

    try {
        if (!user) await signInAnonymously(auth);
        const dataArrRef = await getDocs(collectionQuery);
        dataArrRef.forEach((doc) =>
            dataArr.push({
                ...doc.data(),
                ...formatDateValues(doc.data()),
                id: doc.id,
            })
        );
        dataArrRef.size && setData(dataArr);
    } catch (error) {
        if (showError) {
            toast.error(typeof showError === 'string' ? showError : error?.message);
        }
        // console.log(error, 'fetch collection error');
    } finally {
        setLoading(false);
    }
};

export const fetchDocument = async (documentPath, documentId, setLoading, setData, showError = false) => {
    setLoading(true);

    try {
        const docRef = doc(db, documentPath, documentId);
        const docSnap = await getDoc(docRef);

        setData(
            docSnap.exists()
                ? {
                      ...docSnap.data(),
                      ...formatDateValues(docSnap.data()),
                  }
                : null
        );
    } catch (error) {
        if (showError) {
            const customMessage = showError.trim();
            toast.error(customMessage || error?.message);
        }
        console.log(error, 'fetch doc error');
    } finally {
        setLoading(false);
    }
};

export const fetchCollectionCount = async (collectionPath, setCount) => {
    try {
        const collectionSnapshot = await getCountFromServer(collection(db, collectionPath));
        setCount(collectionSnapshot.data().count);
    } catch (error) {
        toast.error(`Error fetching total number of ${collectionPath}. Please reload the page.`);
    }
};
