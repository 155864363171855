import { ROUTES } from 'constants';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { logout } from 'utils/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Dropdown from 'components/Dropdown';
import { setRole } from 'redux/slices/appSlice';
import capitalizeWords from 'utils/capitalizeWords';
import getInitials from 'utils/getInitials';

const ProfileDropdown = ({ close }) => {
    const { authRole } = useSelector((state) => state.app);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const mapRoles = user?.roles?.filter((role) => role !== authRole && user?.activeStatuses[role]);
    const navigate = useNavigate();

    return ReactDOM.createPortal(
        <>
            <div className="fixed w-screen h-screen top-0 left-0 z-[299]" onClick={() => close(false)} />
            <div
                className={`rounded flex absolute right-[25px] mt-[0px] top-[60px] z-[300] w-[250px]  flex-col bg-white border border-gray-300 border-t-primary-dark border-t-[4px] drop-shadow-md duration-300 `}
            >
                {/* first section */}
                <Link to={ROUTES.PROFILE} onClick={() => close(false)}>
                    <div className="flex space-x-[10px] items-center my-[16px] px-4 group cursor-pointer">
                        {user?.photoURL ? (
                            <img
                                src={user.photoURL}
                                alt={user?.fullName + ' image'}
                                className="rounded-full object-cover w-[40px] h-[40px] mr-1"
                            />
                        ) : (
                            <span className="rounded-full w-[40px] h-[40px] bg-primary text-white grid place-items-center font-medium">
                                {getInitials(user.fullName)}
                            </span>
                        )}
                        <div className={`${user.photoURL ? 'w-[calc(100%-45px)]' : 'w-[calc(100%-49px)]'}`}>
                            <p className="text-[20px] group-hover:text-primary-dark">
                                {capitalizeWords(user.fullName)}
                            </p>
                            <p className="text-[12px] break-words">{user?.email}</p>
                        </div>
                    </div>
                </Link>
                <div className="border-t border-gray-300 py-[16px] px-[20px] flex flex-col space-y-[15px]">
                    {!!mapRoles?.length && (
                        <Dropdown
                            dropdown={<p className="hover:text-primary-dark cursor-pointer">Switch Account</p>}
                            dropdownContainerClasses={'left-[-15px] top-[25px] shadow-dropdownShadow rounded'}
                            name={'quickAction'}
                            dropdownOptions={mapRoles?.map((role) => ({
                                label: `Switch to ${role === 'member' ? 'affiliate' : role} account`,
                                value: role,
                                action: () => {
                                    dispatch(setRole(role));
                                    navigate(ROUTES.HOMEPAGE);
                                    toast.success(`Switched to ${role === 'member' ? 'affiliate' : role} account!`);
                                },
                            }))}
                            dropdownItemsClasses={'hover:text-primary-dark cursor-pointer'}
                        />
                    )}
                    <p
                        className="hover:text-primary-dark cursor-pointer"
                        onClick={() => {
                            close(false);
                            logout();
                        }}
                    >
                        Sign Out
                    </p>
                </div>
            </div>
        </>,
        document.querySelector('.dropdown-container')
    );
};

export default ProfileDropdown;
