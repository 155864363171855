import Notification from 'components/Card/Notification';
import { ROUTES } from 'constants';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

const NotificationDropdown = ({ nots, closeNotification }) => {
    return ReactDOM.createPortal(
        <>
            <div className="fixed w-screen h-screen top-0 left-0 z-[299]" onClick={() => closeNotification(false)} />
            <div
                className={`hidden rounded md:flex absolute max-h-[80vh] overflow-y-auto right-[80px] mt-[0px] top-[60px] z-[300] w-[350px] flex-col bg-white border border-gray-300 border-t-primary-dark border-t-[4px] drop-shadow-md duration-300 `}
            >
                <div className="flex flex-col divide-y-gray-700 divide-y group cursor-pointer">
                    {nots.map((not) => (
                        <Notification
                            title={not.title}
                            link={not?.link}
                            content={not.text}
                            time={not.createdAt}
                            key={not.id}
                        />
                    ))}
                </div>

                <Link
                    to={ROUTES.NOTIFICATIONS}
                    className="bg-primary/100 sticky left-0 bottom-0 z-10 w-full text-white grid place-items-center py-2 transition-colors h-[45px]"
                >
                    View all
                </Link>
            </div>
        </>,
        document.querySelector('.dropdown-container')
    );
};

export default NotificationDropdown;
