import { createSlice } from '@reduxjs/toolkit';
import { getFromStorage, saveToStorage } from 'utils';

const initialState = {
    toggleMobileMenu: true,
    courseCategories: null,
    selectedCourseId: '',
    courses: null,
    authRole: getFromStorage('authRole') ?? null,
    learningType: null,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setToggleMobileMenu: (state, { payload }) => {
            state.toggleMobileMenu = payload ?? !state.toggleMobileMenu;
        },
        setCourseCategories: (state, { payload }) => {
            state.courseCategories = payload;
        },
        setSelectedCourseId: (state, { payload }) => {
            state.selectedCourseId = payload;
        },
        setCourses: (state, { payload }) => {
            const nonDuplicateArr = [state.courses, payload]
                .flat()
                .filter((obj) => obj)
                .filter((obj, index, self) => index === self.findIndex((t) => t.id === obj.id));
            state.courses = nonDuplicateArr;
        },
        setRole: (state, { payload }) => {
            saveToStorage('authRole', payload);
            state.authRole = payload;
        },
        setLearningType: (state, { payload }) => {
            state.learningType = payload;
        },
    },
});

export const {
    setToggleMobileMenu,
    setCourseCategories,
    setSelectedCourseId,
    setCourses,
    setRole,
    setLearningType,
} = appSlice.actions;
export default appSlice.reducer;
