/* eslint-disable react-hooks/exhaustive-deps */
import { ROUTES } from 'constants';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import authBG from 'assets/images/tli-auth-bg.svg';
import formBGStudent from 'assets/images/auth-form-bg-5.jpg';
import formBGMember from 'assets/images/member-signup.jpg';
import formBGAdmin from 'assets/images/admin.svg';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRole } from 'redux/slices/appSlice';
import { ROLES } from 'constants';

const OnboardingLayout = () => {
    const { authRole } = useSelector((state) => state.app);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const isResetPage = pathname === ROUTES.RECOVER;
    const isLoginPage = pathname === ROUTES.LOGIN;
    const isAdminPage = pathname === ROUTES.ADMIN_LOGIN;
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (isAdminPage) {
            if (authRole !== 'admin') {
                dispatch(setRole('admin'));
            }
        }

        if (!authRole) {
            dispatch(setRole(ROLES.STUDENT));
        }
    }, [isAdminPage, authRole]);

    if (user || (authRole === 'admin' && !isAdminPage)) {
        return <Navigate to={ROUTES.HOMEPAGE} replace />;
    }

    return (
        <div
            className={`h-screen w-screen !bg-cover flex justify-center items-center`}
            style={{
                background: `linear-gradient(rgba(247, 247, 247, 0.95), rgba(247, 247, 247, 0.95)), url(${authBG}) no-repeat fixed center`,
            }}
        >
            <div
                className={`flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-lg lg:max-w-4xl ${
                    isResetPage && 'h-[522px]'
                }`}
            >
                <div
                    className={`hidden lg:block lg:w-1/2 !bg-cover ${isAdminPage && 'border-r'}`}
                    style={{
                        background: ` url(${
                            authRole === 'expert'
                                ? 'https://firebasestorage.googleapis.com/v0/b/tli-skills-e854e.appspot.com/o/african-girl-smiling-standing-near-marker-whiteboard-white-wall-min.jpg?alt=media&token=4f3ec206-1d8b-485d-9aca-e0fe5ef445b7'
                                : authRole === 'member'
                                ? formBGMember
                                : isAdminPage
                                ? formBGAdmin
                                : formBGStudent
                        }) no-repeat center`,
                        backgroundPositionX: `left !important`,
                    }}
                />

                <div
                    className={`w-full px-6 py-8 md:px-8 lg:w-1/2 ${(isLoginPage || isAdminPage) && 'py-[90px]'} ${
                        isResetPage && '!pt-28 !pb-16'
                    }`}
                >
                    <h2 className={`text-2xl font-semibold text-center mb-3`}>TLI Skills</h2>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default OnboardingLayout;
