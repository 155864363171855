/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ROUTES } from 'constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GiTeacher } from 'react-icons/gi';
import { TbBooks } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import scrollVertical from 'utils/scrollVertical';
import { setRole, setToggleMobileMenu } from 'redux/slices/appSlice';
import useScreenSize from 'hooks/useScreenSize';
import getInitials from 'utils/getInitials';
import capitalizeWords from 'utils/capitalizeWords';
import { logout } from 'utils/firebase';
import Dropdown from 'components/Dropdown';
import { toast } from 'react-toastify';

const SideBar = () => {
    const [open, setOpen] = useState(true);
    const { width } = useScreenSize();
    const isSmallScreen = width < 768;
    const { pathname } = useLocation();
    const isDashboardPages = pathname.includes('/dashboard');
    const { toggleMobileMenu, authRole } = useSelector((state) => state.app);
    const { user: loggedInUser, notifications } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mapRoles = loggedInUser?.roles?.filter((role) => role !== authRole && loggedInUser?.activeStatuses[role]);

    useEffect(() => {
        if (!isSmallScreen && toggleMobileMenu && isDashboardPages) {
            dispatch(setToggleMobileMenu(false));
        }

        if (isSmallScreen && !toggleMobileMenu) {
            !open && setOpen(true);
            scrollVertical(document.getElementById('aside-scroll'));
        }
    }, [isSmallScreen, toggleMobileMenu, open, isDashboardPages]);

    const allowSection = (role) => loggedInUser?.roles?.includes(role) && authRole === role;

    return (
        <div
            className={`${
                isSmallScreen ? 'fixed z-[200]' : `relative ${!isDashboardPages && 'md:hidden'}`
            } transition-all duration-300 ${toggleMobileMenu ? '-left-[110%]' : 'left-0'} `}
        >
            <aside
                id="aside-scroll"
                className={`flex flex-col min-w-max pb-[40px] md:pb-[60px] bg-white drop-shadow-2xl overflow-y-auto ${
                    isDashboardPages ? 'h-full' : 'h-[calc(100vh-60.0938px)] md:h-[calc(100vh-61px)]'
                }`}
            >
                {/* Mobile sections */}
                {isSmallScreen && (
                    <div className="pt-2 pb-3.5 -mb-3 w-[199px] border-b px-4">
                        <>
                            {loggedInUser ? (
                                <>
                                    <p className="text-xs pt-4 text-gray-400">ACCOUNT</p>
                                    <Link to={ROUTES.PROFILE}>
                                        <div className="flex space-x-[10px] items-center my-[16px] group cursor-pointer">
                                            {loggedInUser?.photoURL ? (
                                                <img
                                                    src={loggedInUser.photoURL}
                                                    alt={loggedInUser?.fullName + ' image'}
                                                    className="rounded-full self-start object-cover w-[25px] h-[25px] mr-1"
                                                />
                                            ) : (
                                                <span className="rounded-full w-[25px] self-start h-[25px] bg-primary text-white text-[11px] grid place-items-center font-medium">
                                                    {getInitials(loggedInUser?.fullName)}
                                                </span>
                                            )}
                                            <div
                                                className={`${
                                                    loggedInUser?.photoURL
                                                        ? 'w-[calc(100%-45px)]'
                                                        : 'w-[calc(100%-49px)]'
                                                }`}
                                            >
                                                <p className="text-sm group-hover:text-primary-dark">
                                                    {capitalizeWords(loggedInUser?.fullName)}
                                                </p>
                                                <p className="text-[10px] break-words">{loggedInUser?.email}</p>
                                            </div>
                                        </div>
                                    </Link>

                                    <div className="pt-0.5 pb-[16px] flex flex-col space-y-[15px]">
                                        {!!mapRoles?.length && (
                                            <Dropdown
                                                dropdown={
                                                    <p className="hover:text-primary-dark cursor-pointer flex items-center">
                                                        <span class="material-icons-outlined text-[20px] mr-5">
                                                            switch_account
                                                        </span>
                                                        Switch Account
                                                    </p>
                                                }
                                                dropdownContainerClasses={
                                                    'left-[-17.5px] top-[25px] shadow-dropdownShadow rounded'
                                                }
                                                name={'quickAction'}
                                                dropdownOptions={mapRoles?.map((role) => ({
                                                    label: `Switch to ${
                                                        role === 'member' ? 'affiliate' : role
                                                    } account`,
                                                    value: role,
                                                    action: () => {
                                                        dispatch(setRole(role));
                                                        navigate(ROUTES.HOMEPAGE);
                                                        toast.success(
                                                            `Switched to ${
                                                                role === 'member' ? 'affiliate' : role
                                                            } account!`
                                                        );
                                                    },
                                                }))}
                                                dropdownItemsClasses={'hover:text-primary-dark cursor-pointer'}
                                            />
                                        )}
                                        <p
                                            className="hover:text-red-500 cursor-pointer flex items-center"
                                            onClick={() => logout()}
                                        >
                                            <span class="material-icons-outlined text-[20px] mr-5">logout</span>
                                            Sign Out
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p className="text-xs pt-4 pb-3 text-gray-400">AUTHENTICATION</p>
                                    <Dropdown
                                        dropdown={
                                            <p className="hover:text-primary-dark cursor-pointer flex items-center py-3">
                                                <span class="material-icons-outlined text-[20px] mr-5">login</span>
                                                Login
                                            </p>
                                        }
                                        dropdownContainerClasses={
                                            'left-[-10px] top-[35px] shadow-dropdownShadow rounded'
                                        }
                                        name={'quickAction'}
                                        dropdownOptions={[
                                            {
                                                label: 'Affiliate Login',
                                                value: 'affiliate',
                                                action: () => {
                                                    dispatch(setRole('member'));
                                                    navigate(ROUTES.LOGIN);
                                                },
                                            },
                                            {
                                                label: 'Expert Login',
                                                value: 'expert',
                                                action: () => {
                                                    dispatch(setRole('expert'));
                                                    navigate(ROUTES.LOGIN);
                                                },
                                            },
                                            {
                                                label: 'Achiever Login',
                                                value: 'achiever',
                                                action: () => {
                                                    dispatch(setRole('achiever'));
                                                    navigate(ROUTES.LOGIN);
                                                },
                                            },
                                        ]}
                                        dropdownItemsClasses={'hover:text-primary-dark cursor-pointer'}
                                    />
                                </>
                            )}
                        </>
                    </div>
                )}

                {/* General */}
                <div className="pt-3 pb-3.5 flex flex-col justify-between">
                    <p className="text-xs px-4 pt-4 pb-3 text-gray-400">GENERAL</p>

                    <Bar
                        path={loggedInUser ? ROUTES.HOMEPAGE : ROUTES.HOMELANDING}
                        content={'Home'}
                        iconName={'home'}
                        open={open}
                    />
                    <Bar path={ROUTES.COURSES} content={'Courses'} iconName={'smart_display'} open={open} />

                    {loggedInUser && (
                        <Bar
                            path={ROUTES.NOTIFICATIONS}
                            content={'Notifications'}
                            iconName={'notifications'}
                            open={open}
                            notsLength={isSmallScreen ? notifications?.length : ''}
                        />
                    )}
                </div>

                {/* Achiever */}
                {allowSection('achiever') && (
                    <div className="border-t border-gray-200 pb-6">
                        <p className="text-xs px-4 pt-4 pb-3 text-gray-400">ACHIEVER</p>
                        <Bar path={ROUTES.STUDENT} content={'Achiever Dashboard'} iconName={'school'} open={open} />
                        {/* <Bar path={''} content={'My Courses'} iconName={'library_books'} open={open} /> */}
                        {/* <Bar path={''} content={'Assignments'} iconName={'quiz'} open={open} /> */}
                        {/* <Bar
                            path={ROUTES.STUDENT_CERTIFICATE}
                            content={'My Certificates'}
                            iconName={'workspace_premium'}
                            open={open}
                        /> */}
                        {/* <Bar
                            path={ROUTES.MY_COURSES}
                            content={'My Courses'}
                            iconName={'laptop_chromebook'}
                            open={open}
                        /> */}
                        {/* <Bar path={ROUTES.QUIZ} content={'Quiz'} iconName={'quiz'} open={open} /> */}
                        {/* <Bar path={ROUTES.FORUMS} content={'Forum'} iconName={'forum'} open={open} /> */}
                    </div>
                )}

                {/* Expert */}
                {allowSection('expert') && (
                    <div className="border-t border-gray-200 pb-6">
                        <p className="text-xs px-4 pt-4 pb-3 text-gray-400">EXPERT</p>
                        <Bar path={ROUTES.TUTOR} content={'Expert Dashboard'} iconName={<GiTeacher />} open={open} />
                        <Bar path={ROUTES.TUTOR_COURSES} content={'Courses'} iconName={<TbBooks />} open={open} />
                        <Bar
                            path={ROUTES.TUTOR_CREATE_COURSE}
                            content={'Create Course'}
                            iconName={'create_new_folder'}
                            open={open}
                        />
                        <Bar path={ROUTES.TUTOR_PAYMENT} content={'Payment'} iconName={'paid'} open={open} />

                        {/* <Bar path={ROUTES.TUTOR_EARNING} content={'Earning'} iconName={'payments'} open={open} /> */}
                        {/* <Bar
                            path={ROUTES.TUTOR_PAYOUT}
                            content={'Payout'}
                            iconName={'account_balance_wallet'}
                            open={open}
                        /> */}
                    </div>
                )}

                {/* Member */}
                {allowSection('member') && (
                    <div className="border-t border-gray-200 pb-6">
                        <p className="text-xs px-4 pt-4 pb-3 text-gray-400">AFFILIATE</p>
                        <Bar path={ROUTES.MEMBER} content={'Affiliate Dashboard'} iconName={'group'} open={open} />
                        <Bar
                            path={ROUTES.MEMBER_TRAINING}
                            content={'Trainings'}
                            iconName={'cast_for_education'}
                            open={open}
                        />
                        <Bar path={ROUTES.MEMBER_PAYMENT} content={'Payment'} iconName={'paid'} open={open} />
                        {/* <Bar path={ROUTES.FORUMS} content={'Forum'} iconName={'forum'} open={open} /> */}
                    </div>
                )}

                {/* Admin */}
                {allowSection('admin') && (
                    <div className="border-t border-gray-200 pb-6">
                        <p className="text-xs px-4 pt-4 pb-3 text-gray-400">ADMIN</p>
                        <Bar
                            path={ROUTES.ADMIN}
                            content={'Admin Dashboard'}
                            iconName={'admin_panel_settings'}
                            open={open}
                        />

                        <Bar
                            path={ROUTES.ADMIN_TRAINING}
                            content={'Trainings'}
                            iconName={'model_training'}
                            open={open}
                        />
                    </div>
                )}
            </aside>

            {isDashboardPages && (
                <div
                    onClick={() => {
                        setOpen(!open);
                    }}
                    className={`max-md:hidden material-icons cursor-pointer bg-primary-dark rounded-[50%] flex justify-center items-center w-[40px] h-[40px] text-[30px] z-[400] text-white absolute right-[-13px] top-[60px] transition-transform duration-300 ${
                        open && 'rotate-180'
                    }`}
                >
                    chevron_right
                </div>
            )}
        </div>
    );
};

const Bar = ({ path, content, iconName, open, notsLength }) => {
    const location = useLocation();

    return (
        <Link
            className={`flex items-center px-4 py-[12px] transition-colors duration-300 group ${
                location.pathname === path ? 'bg-primary/20' : 'hover:bg-primary/10'
            }`}
            to={path}
            title={content}
        >
            <span
                className={`transition-colors duration-300 ${
                    location.pathname === path
                        ? 'text-primary material-icons'
                        : 'group-hover:text-primary material-icons-outlined'
                } ${open ? 'text-[20px]' : 'text-[25px] mx-auto ml-[9px] transition-all'}`}
            >
                {iconName}
            </span>

            {open && (
                <span
                    className={`ml-5 mr-4 text-[14px] transition-colors duration-300 relative ${
                        location.pathname === path ? 'text-primary' : 'group-hover:text-primary'
                    }`}
                >
                    {content}

                    {content === 'Notifications' && notsLength && (
                        <span className="absolute -top-[3px] -right-[15px] rounded-full bg-primary text-white text-[9px] h-[17.3px] w-[18px] pt-[px] text-center font-extrabold border-2 border-white">
                            {notsLength}
                        </span>
                    )}
                </span>
            )}
        </Link>
    );
};

export default SideBar;
