import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    createCourseData: null,
};

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setCourseReqData: (state, { payload }) => {
            state.createCourseData = payload;
        },
    },
});

export const { setCourseReqData } = adminSlice.actions;
export default adminSlice.reducer;
