import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getFromStorage, saveToStorage } from 'utils';

const initialState = {
    items: getFromStorage('basket') ?? [],
};

export const basketSlice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        addItemToBasket: (state, action) => {
            const filteredBasket = state.items.filter((item) => item.id !== action.payload.id);
            const updatedBasket = [...filteredBasket, action.payload];
            saveToStorage('basket', updatedBasket);
            state.items = updatedBasket;
        },
        removeItemFromBasket: (state, action) => {
            let copyOfBasket = [...state.items];
            const index = state.items.findIndex((item) => item.id === action.payload.id);

            if (index >= 0) {
                copyOfBasket.splice(index, 1);
                saveToStorage('basket', copyOfBasket);
                state.items = copyOfBasket;
            } else {
                toast.error('This course is not in the cart');
            }
        },
        emptyCart: (state, action) => {
            saveToStorage('basket', []);
            state.items = [];
        },
    },
});

export const { addItemToBasket, removeItemFromBasket, emptyCart } = basketSlice.actions;
export default basketSlice.reducer;
