import { configureStore } from '@reduxjs/toolkit';
import basketReducer from './slices/basketSlice';
import auth from './slices/authSlice';
import app from './slices/appSlice';
import admin from './slices/adminSlice';

export const store = configureStore({
    reducer: {
        app,
        basket: basketReducer,
        auth,
        admin,
    },
});
