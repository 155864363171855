import SideBar from 'layouts/components/SideBar';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import Nav from 'layouts/components/Nav';

import { useSelector } from 'react-redux';
import { ROUTES } from 'constants';
import { Suspense } from 'react';

const DashboardLayout = () => {
    const { user } = useSelector((state) => state.auth);
    const { pathname } = useLocation();
    const scrollElmRef = useRef();

    useEffect(() => {
        // scrollElmRef?.current && scrollVertical(document.getElementById(scrollElmRef.current));
    }, [pathname]);

    if (!user) {
        return <Navigate to={ROUTES.HOMELANDING} replace />;
    }

    return (
        <>
            <Nav />
            <div className="flex h-[calc(100vh-60.0938px)] md:h-[calc(100vh-61px)]">
                <SideBar />

                <main
                    className="overflow-y-auto h-full flex-1 bg-[#f3f3f4] scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-thin"
                    id={'page-scroller'}
                    ref={scrollElmRef}
                >
                    <Suspense fallback={<div className="w-full h-full animate-pulse" />}>
                        <Outlet />
                    </Suspense>
                </main>
            </div>
        </>
    );
};

export default DashboardLayout;
